import React, { useState, useRef } from 'react'
import './AudioRecorder.css'

const AudioRecorder = ({ updateResponse }) => {
  const [isRecording, setIsRecording] = useState(false)
  const [recordStatus, setRecordStatus] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const mediaRecorderRef = useRef(null)
  const audioChunksRef = useRef([])

  // Открытие модального окна и запуск записи
  const handleStartRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream)
        mediaRecorderRef.current.start()
        setRecordStatus('Recording...')
        setIsRecording(true)
        setIsModalOpen(true) // Открываем модальное окно

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data)
        }

        mediaRecorderRef.current.onstop = () => {
          setRecordStatus('Recording complete, sending to server...')
          const audioBlob = new Blob(audioChunksRef.current, {
            type: 'audio/wav',
          })
          sendAudioToServer(audioBlob)
          audioChunksRef.current = []
        }
      })
      .catch((err) => {
        setRecordStatus('Microphone access error.')
      })
  }

  // Остановка записи
  const handleStopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === 'recording'
    ) {
      mediaRecorderRef.current.stop()
      setIsRecording(false)

      // Остановите все треки потока
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop())
      }
    }
  }

  // Отправка аудио на сервер
  const sendAudioToServer = (audioBlob) => {
    const formData = new FormData()
    formData.append('audio', audioBlob, 'audio.wav')

    fetch('https://lawmind.pro:5000/transcribe', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        updateResponse(`Transcription: ${data.transcript || 'Error'}`)
        sendTranscriptionToChat(data.transcript) // Отправляем текст на сервер
      })
      .catch((error) => {
        updateResponse(`Error sending audio: ${error.message}`)
      })
  }

  // Отправка расшифрованного текста на сервер
  const sendTranscriptionToChat = (transcription) => {
    fetch('https://lawmind.pro:5000/chat', {
      method: 'POST',
      body: JSON.stringify({ prompt: transcription }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        updateResponse(`${data.response || 'Error'}`)
        setRecordStatus('Complete!') // Обновляем статус
        setIsModalOpen(false) // Закрываем модальное окно после получения ответа
      })
      .catch((error) => {
        updateResponse(`Error sending text: ${error.message}`)
      })
  }

  return (
    <div>
      {/* Кнопка для открытия модального окна и начала записи */}
      <button
        onClick={handleStartRecording}
        className='startRecordingButton'
      ></button>

      {/* Модальное окно для записи */}
      {isModalOpen && (
        <div className='audioModal'>
          <div className='audioModal-content'>
            <p>{recordStatus}</p>

            {/* Кнопка для остановки записи */}
            <button
              onClick={handleStopRecording}
              className='stopRecordingButton'
            ></button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AudioRecorder
