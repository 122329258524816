import React, { useState, useRef, useEffect } from 'react'
import './Camera.css'
import loading from '../data/amalie-steiness.gif'

const Camera = ({ updateResponse }) => {
  const [isCameraOpen, setIsCameraOpen] = useState(false)
  const [currentFacingMode, setCurrentFacingMode] = useState('environment')
  const [videoStream, setVideoStream] = useState(null)
  const [isLoading, setIsLoading] = useState(false) // Для отображения GIF
  const videoElement = useRef(null)

  const openCamera = () => {
    const constraints = {
      video: { facingMode: currentFacingMode },
    }

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        if (videoElement.current) {
          videoElement.current.srcObject = stream
        }
        setIsCameraOpen(true)
        setVideoStream(stream)
      })
      .catch((error) => {
        console.error('Ошибка доступа к камере:', error)
        alert('Не удалось открыть камеру: ' + error.message)
      })
  }

  const closeCamera = () => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => track.stop())
    }
    if (videoElement.current) {
      videoElement.current.srcObject = null
    }
    setIsCameraOpen(false)
    setIsLoading(false) // Останавливаем отображение GIF
  }

  const switchCamera = () => {
    setCurrentFacingMode((prevMode) =>
      prevMode === 'environment' ? 'user' : 'environment'
    )
    closeCamera()
    openCamera()
  }

  const captureImage = () => {
    const canvas = document.createElement('canvas')
    const video = videoElement.current
    if (video) {
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      const context = canvas.getContext('2d')
      context.drawImage(video, 0, 0)
      const imageDataUrl = canvas.toDataURL('image/jpeg')
      setIsLoading(true) // Показываем GIF
      sendImageDataToServer(imageDataUrl)
    }
  }

  const sendImageDataToServer = (imageDataUrl) => {
    const byteString = atob(imageDataUrl.split(',')[1])
    const mimeString = imageDataUrl.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    const blob = new Blob([ab], { type: mimeString })

    const formData = new FormData()
    formData.append('image', blob, 'photo.jpeg')

    fetch('https://lawmind.pro:5000/upload_image', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        updateResponse(`Распознанный текст: ${data.text || 'Ошибка'}`)
        setIsLoading(false) // Останавливаем показ GIF после получения ответа
        closeCamera() // Закрываем камеру и модальное окно
      })
      .catch((error) => {
        console.error('Ошибка отправки изображения:', error)
        setIsLoading(false) // Останавливаем показ GIF в случае ошибки
        closeCamera() // Закрываем камеру и в случае ошибки
      })
  }

  useEffect(() => {
    if (isCameraOpen) {
      openCamera()
    }
    return () => {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => track.stop())
      }
    }
  }, [isCameraOpen, currentFacingMode])

  return (
    <div>
      <button
        className='cameraButton'
        onClick={() => setIsCameraOpen((prev) => !prev)}
      ></button>

      {isCameraOpen && (
        <div className={`modal ${isCameraOpen ? 'open' : ''}`}>
          <div className='modal-content' onClick={(e) => e.stopPropagation()}>
            {isLoading ? (
              <img src={loading} alt='Loading...' className='loadingGif' />
            ) : (
              <video ref={videoElement} autoPlay playsInline />
            )}
            <div className='controls'>
              {!isLoading && (
                <>
                  <button
                    className='captureButton'
                    onClick={captureImage}
                  ></button>
                  <button
                    className='cancelButton'
                    onClick={closeCamera}
                  ></button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Camera
