import React, { useState } from 'react'
import './FileUpload.css' // Подключаем стили
import uploadIcon from '../data/upload.png'

const FileUpload = ({ updateResponse }) => {
  const [file, setFile] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]
    if (selectedFile) {
      setFile(selectedFile)
      setIsModalOpen(true)
      event.target.value = null // Сбрасываем input после выбора файла
    }
  }

  const handleSubmit = async () => {
    if (!file) {
      alert('Пожалуйста, выберите файл')
      return
    }

    setIsLoading(true)
    const formData = new FormData()
    formData.append('image', file)

    try {
      const response = await fetch('https://lawmind.pro:5000/upload_image', {
        method: 'POST',
        body: formData,
      })

      const data = await response.json()
      updateResponse(`Распознанный текст с файла: ${data.text || 'Ошибка'}`)
    } catch (error) {
      updateResponse(`Ошибка загрузки файла: ${error.message}`)
    } finally {
      setIsLoading(false)
      setIsModalOpen(false)
      setFile(null)
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <label htmlFor='file-input' className='uploadIcon'>
        <img src={uploadIcon} alt='Upload file' />
      </label>
      <input
        id='file-input'
        type='file'
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      <div
        className={`uploadModal ${isModalOpen ? 'open' : ''}`}
        onClick={closeModal}
      >
        <div
          className='uploadModal-content'
          onClick={(e) => e.stopPropagation()}
        >
          {isLoading ? (
            <img
              src='/path/to/loading.gif'
              alt='Загрузка...'
              className='loadingGif'
            />
          ) : (
            <>
              <h4>{file && file.name}</h4>
              <button onClick={handleSubmit}>Upload</button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default FileUpload
