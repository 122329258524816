import MainForm from './MainForm'

const HomePage = () => {
  return (
    <div className='chatWrapper'>
      <h1>AI Homework</h1>
      <MainForm />
    </div>
  )
}

export default HomePage
