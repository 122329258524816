import './ResponseField.css'

const ResponseField = ({ setResponseText, responseText }) => {
  function clearResponseField() {
    setResponseText([])
  }

  return (
    <div className='responseField'>
      <div className='clearButtonContainer'>
        <button className='clearButton' onClick={clearResponseField}></button>
      </div>
      {responseText.map((response, index) => {
        return (
          <div className='response' key={index}>
            {response}
          </div>
        )
      })}
    </div>
  )
}

export default ResponseField
