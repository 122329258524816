import React, { useState, useCallback } from 'react'
import './TextPromptForm.css'

const TextPromptForm = ({ updateResponse }) => {
  const [prompt, setPrompt] = useState('')

  const handlePromptInput = useCallback(
    (event) => {
      event.preventDefault()

      // Проверка, что поле ввода не пустое
      if (prompt.trim() === '') {
        return
      }

      fetch('https://lawmind.pro:5000/chat', {
        method: 'POST',
        body: JSON.stringify({
          prompt: prompt,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          updateResponse(json.response)
        })
        .catch((error) => {
          updateResponse(`Ошибка: ${error.message}`)
        })

      setPrompt('') // Очистка поля ввода после отправки
    },
    [prompt, updateResponse]
  )

  return (
    <form onSubmit={handlePromptInput}>
      <div className='inputForm'>
        <input
          className='inputField'
          type='text'
          placeholder='Type here your request...'
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <button className='sendButton' type='submit'>
          Send
        </button>
      </div>
    </form>
  )
}

export default TextPromptForm
